<template>
    <div class="select-component">
        <v-select v-model="object" class="w-100 mr-0" ref="select" :label="label" :options="options" :placeholder="placeholder" :clearable="clearable" :disabled="disabled" :reduce="item => reduceField ? item[reduceField] : item">
            <span slot="no-options" @click="$refs.select.open = false">
                Žal ni možnosti ujemanja
            </span>
            <template #open-indicator="{ attributes }">
                <span style="background: #72a5d8 !important;">
                    <div v-bind="attributes">
                        <FeatherIcon class="text-white" icon="ChevronDownIcon"/>
                    </div>
                </span>
            </template>


            <template v-slot:option="option">
            
                {{ customDropdownLabelFn ? customDropdownLabelFn(option) : option[label || 'label'] }}
            </template>
            <template v-slot:selected-option="option">
                {{ customSelectedLabelFn ? customSelectedLabelFn(option) : option[label || 'label'] }}
            </template>
        </v-select>
    </div>
</template>

<script>
    import vSelect from 'vue-select'

    export default {
        components: {
            vSelect
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            options: {
                type: Array
            },
            optionsMultiple: {
                type: Object
            },
            placeholder: {
                type: String
            },
            type: {
                type: String
            },
            obj: {
                type: [Array, Boolean, Number, String, Object],
                default: false
            },
            value: {
                default: null
            },
            valueMultiple: {
                type: Object
            },
            arrayValues: {
                type: Array
            },
            label: {
                type: String
            },
            clearable: {
                type: Boolean,
                default: false
            },
            reduceField: {
                type: String
            },
            disabled: {
                type: Boolean, 
                default: false
            },
            customSelectedLabelFn: {
                type: Function,
                default: null
            },
            customDropdownLabelFn: {
                type: Function,
                default: null
            }
        },
        methods: {
            cleanUpValidation(val) {
                this.arrayValues.splice(val)
            }
        },
        computed: {
            object: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('change', value)
                }
            }
        }
    }
</script>

<style>
.select-component .vs__dropdown-option{
    white-space: initial !important;
}

.select-component .vs--disabled{
    opacity: 0.5;
}

.select-component .vs--disabled .vs__open-indicator{
    background: #72a5d8 !important;
}

.select-component .vs--disabled .vs__search, .select-component .vs--disabled .vs__clear{
    background: transparent !important;
}

.select-component input::placeholder {
  color: black !important;
}
.select-component input:focus {
  background-color: white;
  border-color: white !important;
  box-shadow: 0 3px 10px 0 rgba(0,0,0, 0.1) !important;
}
.vs__selected-options {
  padding: 0 !important;
}

.select-component .vs__dropdown-toggle {
  padding: 0 !important;
  background-color: #E7E6E6 !important;
  border: none !important;
  line-height: 2.5 !important;
}
.select-component .vs__actions .vs__clear svg{
    fill: #72a5d8 !important;
}

.select-component .vs__actions .vs__open-indicator {
  padding: 0 8px !important;
  margin: 0px !important;
  /* background: #72a5d8 !important; */
  /* transform: rotate(0deg) !important; */
}

.select-component .vs__dropdown-menu .vs__dropdown-option{
    width: 100%;
}

.select-component .vs__selected{
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 90%;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 35px;
}

.select-component .vs__actions {
  padding: 0px !important;
}
.select-component .vs__search, .select-component .v-select.vs--single .vs__selected {
  margin: 0 !important;
}
.vs__clear svg {
  fill: white !important;
}
.select-component ul {
  background-color: white;
  border-color: white !important;
  box-shadow: 0 3px 10px 0 rgba(0,0,0, 0.1) !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>